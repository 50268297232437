
import { mdiAsterisk, mdiTrashCanOutline, mdiAlert } from '@mdi/js'

export default {
  props: {
    project: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        image: '',
        id: '',
      }),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete'],
  data: () => ({
    mdiAsterisk,
    mdiTrashCanOutline,
    mdiAlert,
  }),
  computed: {
    approachKeys() {
      if (!this.project.approach) return
      if (typeof this.project.approach === 'string') return
      return Object.keys(this.project.approach)
    },
  },
}
